<template>
  <div class="login d-flex just justify-content-center">
    <form class="from" @submit.prevent="onForgotPassword">
      <div class="form-group">
        <p>
          Введите ваш e-mail, для которого необходимо восстановить пароль.
          <br />
          Вы получите письмо для установки нового пароля.
        </p>
      </div>

      <div class="form-group">
        <b-form-input v-model="email" placeholder="Email"></b-form-input>
      </div>

      <div class="form-group">
        <b-button variant="success" :disabled="!this.email" type="submit"
          >Отправить</b-button
        >

        <span class="ml-3 spinner" v-if="loading">
          <b-spinner variant="success" label="Spinning"></b-spinner>
        </span>
      </div>

      <div class="message text-danger">{{ this.message }}</div>
    </form>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";

export default {
  name: "ForgotPassword",
  data() {
    return {
      email: "",
      loading: false
    };
  },
  methods: {
    ...mapActions('users', ["resetPasswordByEmail"]),
    async onForgotPassword() {
      this.loading = true;

      await this.resetPasswordByEmail(this.email).then(res => {
        this.loading = false;

        if (res.ok) this.$router.push("/").catch(() => {});
      });
    }
  },
  computed: {
    ...mapState(["message"]),
    ...mapState("manufacturers", {
      isManufacturerConfirmed: state => state.confirmed
    }),
    ...mapGetters([
      "isAuth",
      "isAdmin",
      "isManager",
      "isLeader",
      "isManufacturer"
    ]),
  }
};
</script>
